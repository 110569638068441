<template lang="pug">
  .row.ed-grid.m-grid-16
    .m-cols-1
      .cell {{psychologist.firstname}}
    .m-cols-1
      .cell {{psychologist.lastname}}
    .m-cols-1
      .cell
    .m-cols-1
      .cell
    .m-cols-1
      .cell {{psychologist.dni}}
    .m-cols-1
      .cell {{psychologist.phone}}
    .m-cols-1.center
      .cell {{psychologist.District.City.name}}
    .m-cols-1.center
      .cell(v-if="psychologist.District.name") {{psychologist.District.name}}
      .cell(v-else) ---
    .m-cols-1.center
      .cell ---
    .m-cols-1.center
      .cell
    .m-cols-1.center
      .cell
    .m-cols-1
      .cell {{psychologist.patientsActives}}
    .m-cols-1
      .cell {{psychologist.patients}}
    .m-cols-1
      .cell
    .m-cols-1
      .cell
    .m-cols-1
      a Ver Más
</template>

<script>
// import { mapState } from 'vuex'
// import component from '@/components/component.vue'

export default {
  components: {},
  props: {
    // propierty: { required: false, type: String },
    psychologist: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      data: '',
      toggleOption: false
    }
  },

  computed: {},

  watch: {},
  created () {},
  mounted () {},
  updated () {},
  methods: {}
}
</script>

<style lang="sass" scoped>
</style>
